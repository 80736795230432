import { createApp } from 'vue';
import App from './App.vue';
import VueSweetalert2 from 'vue-sweetalert2';
import { install as DiscordMessageComponents } from '@discord-message-components/vue';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import * as Sentry from "@sentry/vue";

import store from './utils/store.js';
import router from './utils/router.js';

import '@discord-message-components/vue/styles';
import "primevue/resources/themes/aura-dark-noir/theme.css";
import "primevue/resources/primevue.min.css";
import 'primeicons/primeicons.css';
import ConfirmationService from 'primevue/confirmationservice';

import "vue-toastification/dist/index.css";
import './assets/css/index.css';
import 'sweetalert2/dist/sweetalert2.min.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faPen, faChevronLeft, faCode, faTag, faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add({ faDiscord, faTwitter, faPen, faShieldHalved, faChevronLeft, faTag, faCode });

import Button from 'primevue/button';
import SelectButton from 'primevue/selectbutton';
import Card from 'primevue/card';
import Menu from 'primevue/menu';
import Sidebar from 'primevue/sidebar';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import InputNumber from 'primevue/inputnumber';
import Checkbox from 'primevue/checkbox';
import Toast from 'primevue/toast';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';

let app = createApp(App);
app.use(router);
app.use(store);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(VueSweetalert2);
app.use(DiscordMessageComponents);
app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.component('Button', Button);
app.component('Card', Card);
app.component('SelectButton', SelectButton);
app.component('Menu', Menu);
app.component('Sidebar', Sidebar);
app.component('Checkbox', Checkbox);
app.component('InputText', InputText);
app.component('Dropdown', Dropdown);
app.component('InputSwitch', InputSwitch);
app.component('InputNumber', InputNumber);
app.component('IconField', IconField);
app.component('InputIcon', InputIcon);
app.component('Toast', Toast);
app.use(ConfirmationService);
app.use(require('vue-cookies'));
if (process.env.NODE_ENV === 'production') Sentry.init({ app,
  dsn: process.env.VUE_APP_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
  ],
  tracesSampleRate: 0.1,
});
app.mount('#app');