<template>
    <nav class="sticky top-0 z-50 table-fixed flex justify-between items-center py-1 px-3 w-full bg-primary shadow-sm text-white">
      <router-link to="/">
        <h1 class="text-3xl md:text-4xl lg:text-5xl font-black font-bebas">BotCord</h1>
      </router-link>
      <ul class="flex justify-center space-x-3 text-base md:text-xl lg:text-2xl">
        <NavItem href="/commands" class="rounded-lg hover:bg-gray-700 duration-500 transition-all my-auto py-1 md:py-0"><span class="mr-2 hidden md:block">Comandos </span><font-awesome-icon icon="code"/></NavItem>
        <NavItem v-if="user.id" href="/dashboard" class="rounded-lg hover:bg-gray-700 duration-500 transition-all">
          <span class="mr-3">{{user.name}}</span>
          <img
            alt="avatar"
            class="rounded-full"
            width="32"
            :src="user.avatar !== '0' ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png` : `https://cdn.discordapp.com/embed/avatars/0.png`"/>
        </NavItem>
        <NavItem v-else href="/login" icon="login" :hover="true">Login</NavItem>
      </ul>
  </nav>
</template>

<script setup>
import NavItem from '@/components/Navbar/NavItem';
import { computed } from 'vue';
import { useStore } from 'vuex';
const store = useStore();

const user = computed (() => {
  return store.state.user;
});
</script>