<template>
  <div id="app" class="font-poppins text-white bg-primary">
    <Navbar/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script setup>
import axios from './utils/axios';
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import { computed, inject } from 'vue';
import { useStore } from 'vuex';

const cookies = inject('$cookies');
const store = useStore();
const user = computed (() => {
  return store.state.user;
});

function getUser() {
  axios.get('/api', { withCredentials: true }).then((res) => {
    if (res.data) {
      cookies.set('user', res.data);
      store.commit('add', res.data);
    } else console.log('[USER] Error on listen user');
  }).catch(() => console.log('[USER] Not logged'));
}
async function created () {
  const userCookies = cookies.get('user');
  if (user.value.id) {
    if (user.value.avatar !== '0') {
      axios.get('https://cdn.discordapp.com/avatars/' + user.value.id + '/' + user.value.avatar + '.png', { params: { t: new Date().getTime() } })
          .then(() => {
            if (!userCookies) cookies.set('user', user.value);
          })
          .catch(() => {
            window.location.href = 'https://botcord.net/logout/';
          });
    }
  } else if (userCookies) store.commit('add', userCookies);
  else getUser();
}
created();
</script>