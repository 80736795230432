import { createRouter, createWebHistory } from 'vue-router';
import store from './store.js';
import { inject } from 'vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Index',
      component: () => import('@/views/Home'),
    },
    {
      path: '/store',
      name: 'Store',
      component: () => import('@/views/Store'),
    },
    {
      path: '/login',
      name: 'Login',
      beforeEnter(to, from, next) {
        if (process.env.NODE_ENV === 'production') window.location.replace(`${process.env.VUE_APP_API_URL}/api/login/`);
        else window.location.replace(`${process.env.VUE_APP_API_DEV_URL}/api/login/`);
      },
    },
    {
      path: '/suporte',
      name: 'Suporte',
      beforeEnter() {
        window.location.replace(process.env.VUE_APP_DISCORD_URL);
      },
    },
    {
      path: '/free-bot',
      name: 'Adicionar',
      beforeEnter() {
        window.location.replace(process.env.VUE_APP_FREEBOT_URL.replace('BASEURL', process.env.VUE_APP_BASE_URL));
      },
    },
    {
      path: '/dashboard/',
      name: 'Dashboard',
      component: () => import('@/views/Dashboard'),
    },
    {
      path: '/dashboard/:id/',
      name: 'Profile',
      component: () => import('@/views/Profile/index.vue'),
    },
    {
      path: '/logout',
      name: 'Logout',
      async beforeEnter() {
        store.commit('logout');
        const $cookies = inject('$cookies');
        $cookies.remove('user');
        await router.push(process.env.VUE_APP_API_URL + '/api/logout/')
        //window.location.replace();
      },
    },
    {
      path: '/commands',
      name: 'Commands',
      component: () => import('@/views/Commands'),
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'Error404',
      async beforeEnter() {
        await router.push('/404');
        //window.location.replace(process.env.VUE_APP_BASE_URL + '/404');
      },
    },
    {
      path: '/404',
      name: 'NotFound',
      component: () => import('@/views/NotFound'),
    },
    {
      path: '/terms',
      name: 'Terms',
      component: () => import('@/views/Terms'),
    },
  ],
});

export default router;