import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";

let id, number, time, title, desc, color, image, autodelete, channeldelete, createrole, roledelete, email, guild, description, token, owner, name, tag, avatar, plan, video, discriminator, imageData, punishment, checkout, prefix, enabled, type, message, channel, state, usage, origin, duration = 'month';

const store = createStore({
  plugins: [createPersistedState({ storage: window.sessionStorage, })],
  state: {
    user: {
      id,
      avatar,
      name,
      tag,
      discriminator,
      email
    },
    bot: [{
      id,
      number,
      guild,
      name,
      owner,
      avatar,
      plan,
      punishment,
      profile: {
        type,
        state,
      },
      welcome: {
        enabled,
        channel,
        type,
        message,
        delete: {
          enabled,
          time
        },
        embed: {
          title,
          desc,
          color,
          image
        },
      },
      leave: {
        enabled,
        channel,
        type,
        message,
        delete: {
          enabled,
          time
        },
        embed: {
          title,
          desc,
          color,
          image
        },
      },
      webhook: {
        id,
        name,
        token
      },
      vip: {
        autodelete,
        roledelete,
        createrole,
        channeldelete,
      },
      vips: [],
      tickets: [],
    }],
    vips: [],
    buy: {
      preference: [],
      imageData,
      checkout,
      origin,
      formValidate: {
        name,
        id,
        prefix,
        duration
      }
    },
    commands: {
      name,
      description,
      video,
      usage,
    },
    option: 'perfil',
    baseURL: process.env.VUE_APP_API_URL,
  },
  getters: {
    user: state => state.user,
    id: state => state.buy.id,
    bot: state => state.bot,
  },
  mutations: {
    add (state, val) {
      state.user = val;
    },
    bots (state, val) {
      state.bot = val;
    },
    profile (state, val) {
      state.bot = [val];
    },
    addImage (state, val) {
      state.bot[0].avatar = val;
    },
    addBanner (state, val) {
      state.bot[0].banner = val;
    },
    cooldownImage (state, val) {
      state.bot[0].cooldownImage = val;
    },
    cooldownBanner (state, val) {
      state.bot[0].cooldownBanner = val;
    },
    preference (state, val) {
      state.buy.preference.push(val);
    },
    logout (state) {
      state.user = {};
    },
    origin (state, val) {
      state.buy.origin = val;
    },
    command (state, val) {
      state.commands = val;
    },
    option (state, val) {
      state.option = val;
    },
    vips (state, val) {
      state.vips = val;
    },
  },
});

export default store;